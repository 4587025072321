import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import styles from "./index.module.css";

const getButton = (variant="outlined", fullWidth, text, size) => {
  return (
    <Button
      variant={variant}
      color="secondary"
      fullWidth={fullWidth}
      style={{ margin: 10 }}
    >
      <Typography
        variant="button"
        style={{
          minWidth: size && size,
          padding: size && "4vw auto",
          fontFamily: "'DM Sans', sans-serif",
          fontWeight: 700,
          fontSize: 15,
          letterSpacing: "1.25px",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

const OutlinedButton = (props) => {
  return (
    <Link
      to={props.link}
      className={`${styles["enroll-button"]} ${styles["outlineButton"]}`}
    >
      {getButton(props.variant, props.fullWidth, props.text, props.size)}
    </Link>
  );
};

const SolidButton = (props) => {
  return (
    <Link to={props.link} className={styles["enroll-button"]}>
      {getButton("contained", props.fullWidth, props.text, props.size)}
    </Link>
  );
};

const SubmitButton = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onSubmit={props.action}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      type="submit"
      className={styles["enroll"]}
    >
      {props.text}
    </Button>
  );
};

export { OutlinedButton, SolidButton, SubmitButton };
