import { useEffect } from "react";
import Shell from "components/shell";
import { Grid, Typography, Box } from "@mui/material";
import  Success from "../../assets/img/Success.png";
import { OutlinedButton } from "components/buttons";
import { useParams } from "react-router-dom";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SUBJECT_PROVIDER,
  SPONSOR_HP,
  SPONSOR_CVS,
  BOLD
} from "../../constants/app";
import PlayStoreImage from "../../assets/img/google-play-badge.png";
import AppleImage from "../../assets/img/Apple_Logo.svg";
import classes from "./index.module.css";

const Confirmation = () => {
  const fetchUserName = () => {
    const name = JSON.parse(localStorage.getItem("_data")).firstName || "User";
    return name;
  };
  // const containerStyles = containerUseStyles();
  const { sponsor, code, subject } = useParams();
  const addDependentLink = `/enroll/${sponsor}/provider`;
  const enrollMyselfLink = `/enroll/${sponsor}/${SUBJECT_EMPLOYEE}`;

  useEffect(() => window.scrollTo(0, 0));
  return (
    <Shell confirmation={true}>
      {((subject === SUBJECT_EMPLOYEE || subject === SUBJECT_DEPENDENT) && (
        <Grid container className={classes.confirmation}>
          <Grid item md={6} className={classes["completion-message"]}>
          <img src = {Success} />
            <Typography variant="h1" className={classes.enrollment_header}>
              Enrollment Complete
            </Typography>
            <Typography
              variant="body2"
              className={classes["success-message"]}
              style={{
                fontSize: 15,
                fontFamily: "'DM Sans', sans-serif",
                fontWeight: 400,
              }}
            >
            {sponsor.toUpperCase() === SPONSOR_HP || sponsor.toUpperCase() === BOLD ? "You will receive a confirmation email from support@alivecor.com containing your activation code. If you have questions or concerns please feel free to reach out to our customer care team." : "Please check your email for a confirmation email from support@alivecor.com. If you have questions or concerns please feel free to reach out to our customer care team."}
            </Typography>
            {/* <Box className={classes['invite-dependent-button-section']}>
            { subject === 'employee' &&
              <>
                <Typography variant="h4" className={classes['sub-headline']}>Have dependents you want to enroll in KardiaComplete?</Typography>
                <div className={classes['add-dependent-container']}>
                  <OutlinedButton text="invite a dependent to enroll" link={addDependentLink} />
                </div>
              </>
            }
            </Box> */}
          </Grid>
          <Grid item md={6} className={classes["code-and-steps"]}>
            <Box>
              <Typography variant="h3" className={classes["next-steps"]}>
                Next steps:
              </Typography>
              {/* <ul>
                <li>
                  <Typography variant="h3" >Download the KardiaComplete app.</Typography>
                </li>
                <li>
                  <Typography variant="h3" className={classes.step1}>Check your email for your activation code. </Typography>
                </li>
                <li>
                  <Typography variant="h3" className={classes.step1}>Complete account creation in the KardiaComplete app using your activation code.</Typography>
                </li>
              </ul> */}
              <div className={classes["textCircleDiv"]}>
                <div className={classes["roundCircle"]}></div>{" "}
                <Typography
                  variant="h3"
                  style={{ fontSize: 17, fontFamily: "'DM Sans', sans-serif" }}
                >
                  Download the KardiaComplete app.{" "}
                </Typography>
              </div>
              <div style={{ margin: "-1rem auto", paddingLeft: "1.4rem" }}>
                <img src={PlayStoreImage} /> <img src={AppleImage} />
              </div>
              <div className={classes["textCircleDiv"]}>
                <div className={classes["roundCircle"]}></div>{" "}
                <Typography
                  variant="h3"
                  style={{ fontSize: 17, fontFamily: "'DM Sans', sans-serif" }}
                >
                  Check your email for your activation code.
                </Typography>
              </div>
              <div className={classes["textCircleDiv"]}>
                <div className={classes["roundCircle"]}></div>{" "}
                <Typography
                  variant="h3"
                  style={{ fontSize: 17, fontFamily: "'DM Sans', sans-serif" }}
                >
                  Complete account creation in the KardiaComplete app using your
                  activation code.
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      )) ||
        (subject === SUBJECT_PROVIDER && (
          <Grid container className={classes.confirmation}>
            <Grid item md={6} className={classes["completion-message"]}>
            <img src = {Success} />
              <Typography variant="h1" className={classes.enrollment_header}>
                Dependents Added
              </Typography>
              <Typography variant="p" className={classes["success-message"]}>
                Your dependents will receive an invitation email from
                support@alivecor.com to begin their enrollment.
              </Typography>
              <div className={classes["add-dependent-container"]}>
                <OutlinedButton link={enrollMyselfLink} text="enroll myself" size = {"24vw"} variant = "contained" />
                {/* <OutlinedButton
                  link={addDependentLink}
                  text="invite another dependent"
                /> */}
                {/* <div className={classes['another-dependent-container']}>
              </div> */}
              </div>
            </Grid>
          </Grid>
        ))}
    </Shell>
  );
};

export default Confirmation;
