import { ENDPOINT as BASE, PATHS, GET_CONFIG, POST_CONFIG } from "./config";

const registrationPath = `${BASE}${PATHS.registration}`;

export const getRegistration = async (id) => {
  const config = { ...GET_CONFIG };
  const resp = await fetch(`${registrationPath}${id}`, config);
  return await resp.json();
};

export const updateRegistration = async (registration) => {
  const config = { ...POST_CONFIG };
  // console.log('before stringify', Object.assign({}, registration));
  config.body = JSON.stringify(registration);
  // console.log(config);
  const resp = await fetch(`${registrationPath}`, config);
  // console.log('before returning json', resp.ok);
  return await resp.json();
};

const dependentEnrolPath = `${BASE}${PATHS.enrolDependent}`;
export const updateDependentEnrolment = async (registration,bold) => {
  //calling api with payload when dependents enroll themselves.
  const config = { ...POST_CONFIG };
  config.body = JSON.stringify(registration);
  const resp = await fetch(`${bold ? `${BASE}${PATHS.boldEnroll}` : dependentEnrolPath}`, config);
  return resp;
};

const dependentInvitePath = `${BASE}${PATHS.inviteDependent}`;
export const updateDependentInvite = async (registration) => {
  //calling api with payload when employee invites dependents
  let a = [];
  const d = Object.entries(registration).reduce((acc, cur) => {
    let [key, value] = cur;
    if (key.includes("dependentEmail")) {
      //converting to array format ie. dependentEmails: [""]
      // return {...acc, 'dependentEmails': [...(acc[value] || ''), value]}
      a.push(value);
      return { ...acc, dependentEmails: a };
    } else {
      return { ...acc, [key]: value };
    }
  }, {});
  const config = { ...POST_CONFIG };
  config.body = JSON.stringify(d);
  const resp = await fetch(`${dependentInvitePath}`, config);
  return await resp.json();
};
