export const SUBJECT_EMPLOYEE = "employee";
export const SUBJECT_DEPENDENT = "dependent";
export const SUBJECT_PROVIDER = "provider";
export const SPONSOR_HP = "HP";
export const SPONSOR_CVS = "CVS";
export const BOLD = "BOLD";
export const NO_USER_FOUND = "Cannot find User";
export const USER_EXIST_CODE_GENERAL_1 = "E#HQIS7";
export const USER_EXIST_CODE_GENERAL_2 = "E#HQIS8";
export const USER_EXIST_CODE_CVS_1 = "E#HS1CV";
export const USER_EXIST_CODE_CVS_2 = "E#HS2CV";
export const USER_EXIST_CODE_CVS_3 = "E#HS3CV";
export const USER_NOT_EXIST_CODE = "E#HS8CV";