import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Typography, Box, Stepper, Step, StepLabel } from "@mui/material";
import Shell from "../shell";
import { OutlinedButton, SolidButton } from "../buttons";
import classes from "./index.module.css";
import { makeStyles } from "@mui/styles";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SPONSOR_CVS,
  BOLD,
  SUBJECT_PROVIDER,
} from "../../constants/app";
const steps = ["Welcome!", "Employee Information"];

const useStyles = makeStyles({
   root: {
     "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
       color: "#174E8C !important",
     },
     "& .css-vnkopk-MuiStepLabel-iconContainer": {
       background: "white",
       borderRadius: "50%",
       padding: "5px",
     },
     "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed":{
       color:"#174E8C !important",
     },
     "& .css-4ff9q7.Mui-active":{
       color: "#174E8C !important",
     },
     "& .css-a5nipc.MuiStepLabel-alternativeLabel":{
      background: "white !important",
      borderRadius: "50% !important",
      padding: "5px !important",
     },
     "& .css-zpcwqm-MuiStepConnector-root":{
      top:"15px !important"
     },
     "& .css-i0u8l7-MuiTypography-root":{
      color:"black !important"
     }
   },
});
const Enrollment = () => {
  const styles = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  let sponsor = useParams().sponsor.toUpperCase();
  let subject = useParams().subject;

  useEffect(() => {
    sponsor === SPONSOR_CVS &&
      navigate(`/enroll/${SPONSOR_CVS}/${SUBJECT_EMPLOYEE}`);
  }, []);

  return (
    <Shell>
      <Grid container className={classes.container}>
        <Grid item sm={12} md={8} lg={8} xl={8}>
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={0}
              alternativeLabel
              className={`${classes["stepperStyle"]} ${styles.root}`}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel className={classes["textStyle"]}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        paddingTop: label === "Welcome!" && 9,
                        fontSize: 18,
                        fontFamily: "'DM Sans', sans-serif",
                        fontWeight: 600,
                        lineHeight: "inherit",
                        color:"black"
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {/* <Success /> */}
          <Grid className={classes["enrollAlignTexts"]}>
            <div className={classes["enrollTextFirst"]}>
              <Typography
                variant="h2"
                style={{
                  color: "#182C3D",
                  marginBottom: "8px",
                  fontSize: 36,
                  fontFamily: "'DM Sans', sans-serif",
                }}
              >
                Welcome to KardiaComplete!
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "'DM Sans', sans-serif",
                  fontSize: 19,
                  fontWeight: 600,
                }}
              >
                Enrollment is fast and simple, and gives you immediate access to
                KardiaComplete at no cost to you.
              </Typography>
            </div>
            {sponsor === BOLD ?  <Typography
                variant="h6" style = {{ fontFamily: "'DM Sans', sans-serif",
                fontSize: 21,
                fontWeight: 700,}}>Please select the option that applies to you:</Typography> :  
            <div>
              <Typography
                variant="h4"
                style={{
                  marginBottom: "5px",
                  fontFamily: "'DM Sans', sans-serif",
                  fontSize: 22,
                }}
              >
                I'm enrolling as a(n):
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "'DM Sans', sans-serif",
                  fontSize: 17,
                  fontWeight: 400,
                }}
              >
                Select Employee if you are the primary member of your insurance
                plan. Select Dependent if you are listed as a dependent on the
                primary insurance holder's plan.
              </Typography>
            </div>}
            <Box className={classes.button_alignment}>
              <SolidButton
                sponsor={params.sponsor}
                text={`${sponsor === BOLD ? "ENROLL MYSELF" : "EMPLOYEE"}`}
                link={`${SUBJECT_EMPLOYEE}`}
                size={"13vw"}
              />
              <OutlinedButton
                sponsor={params.sponsor}
                text={`${sponsor === BOLD ? "ADD a DEPENDENT" : "DEPENDENT"}`}
                link={sponsor === BOLD ? SUBJECT_PROVIDER : SUBJECT_DEPENDENT}
                size={"13vw"}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Shell>
  );
};

export default Enrollment;
